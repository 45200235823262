import React, { useState } from "react"
import Popover from "react-tiny-popover"
import styles from "./popoverItem.module.scss"

export default ({ text, content, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover
      isOpen={isOpen}
      padding={2}
      position={["bottom"]}
      content={<div className={styles.popoverContent}>{content}</div>}
    >
      <span
        role="button"
        tabIndex={0}
        className={styles.popoverItem}
        onMouseEnter={e => setIsOpen(true)}
        onMouseLeave={e => setIsOpen(false)}
        {...rest}
      >
        {text}
      </span>
    </Popover>
  )
}
