import React from "react"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./hero.module.scss"

import { Button } from "../button"
import HeroButtonArrow from "./hero_button_arrow.svg"

const Hero = ({ heroBg, heroImage }) => {
  return (
    <BackgroundImage
      className={styles.hero}
      fluid={heroBg}
      fadeIn={true}
      backgroundColor={"#F8F4F7"}
    >
      <div className={styles.heroContent}>
        <div className={styles.heroImage}>
          <Image fluid={heroImage} />
        </div>
        <div className={styles.heroText}>
          <h1 className={styles.heroHeader}>Static Sites That Move Fast.</h1>
          <p className={styles.heroCaption}>
            We build static sites that let you focus on content and growth,
            instead of wrestling with servers, updating plugins, or waiting on
            changes.
          </p>
          <Button text="Show Me How" href="#the-stack" icon={HeroButtonArrow} />
        </div>
      </div>
    </BackgroundImage>
  )
}

const HeroWithData = () => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      heroBg: file(name: { eq: "hero_bg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImage: file(name: { eq: "hero_image" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { heroBg, heroImage } = data

  return (
    <Hero
      heroBg={heroBg.childImageSharp.fluid}
      heroImage={heroImage.childImageSharp.fluid}
    />
  )
}

export default HeroWithData
