import React from "react"
import Section from "../section"
import PopOverItem from "../popoverItem"
import styles from "./propositions.module.scss"

import BuildFast from "../../images/build_fast.png"
import MadeOfYou from "../../images/made_of_you.png"
import HandsOffDeploys from "../../images/hands_off_deploys.png"

export default () => (
  <div id="features">
    <Section>
      <h3 className={styles.propText}>
        <span className={styles.propIntro}>Think big, build fast.</span>{" "}
        Forge-built sites leverage{" "}
        <span className={styles.propHighlight}>GatsbyJS</span>. Lightning fast
        performance and extensive plugin support makes integration with your
        existing platforms simple.
      </h3>
      <div className={styles.propImage}>
        <img src={BuildFast} alt="" />
      </div>
    </Section>
    <Section>
      <div className={styles.propImage}>
        <img src={MadeOfYou} alt="" />
      </div>
      <h3 className={styles.propText}>
        <span className={styles.propIntro}>Made out of what makes you</span>.
        Make{" "}
        <PopOverItem
          text="Contentful"
          content={
            <div>
              <p className="bold">Need something different?</p>
              <p className="light">
                Wordpress, Drupal, your database, social media channels, and
                more. If you need it, we can source it.
              </p>
            </div>
          }
        />{" "}
        the backbone for your site. Define the data and media that’s important
        to you, to be sourced how and where you need it.
      </h3>
    </Section>
    <Section>
      <h3 className={styles.propText}>
        <span className={styles.propIntro}>Deploy without the worry.</span>{" "}
        Rapid deployment, no servers, no pipelines. With{" "}
        <span className={styles.propHighlight}>Netlify</span>, you can be
        hands-off on site deploys.
      </h3>
      <div className={styles.propImage}>
        <img src={HandsOffDeploys} alt="" />
      </div>
    </Section>
  </div>
)
