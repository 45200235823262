import React from "react"

import styles from "./contactForm.module.scss"

export default () => {
  return (
    <div className={styles.formSection}>
      <h2>Ready when you are.</h2>
      <p>We’re open to new projects, so feel free to drop us a message.</p>
      <div className={styles.formWrap}>
        <form
          className={styles.contactForm}
          name="index-contact"
          method="POST"
          netlify-honeypot="delicious-honey"
          data-netlify="true"
          action="/message-sent"
        >
          <div className={styles.hidden}>
            <input
              name="delicious-honey"
              placeholder={`Ignore this field if you're human.`}
            />
          </div>
          <input type="hidden" name="form-name" value="index-contact" />
          <input
            className={styles.contactFormInput}
            type="email"
            name="email"
            required
            aria-required
            placeholder={"E-mail"}
          />
          <textarea
            className={styles.contactFormTextArea}
            type="text"
            name="message"
            required
            aria-required
            placeholder={"Message"}
          />
          <button className={styles.contactFormButton} type="submit">
            Send
          </button>
        </form>
      </div>
    </div>
  )
}
