import React from "react"
import Hero from "../components/hero"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Propositions from "../components/propositions"
import ContactForm from "../components/contactForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Propositions />
    <ContactForm />
  </Layout>
)

export default IndexPage
